<template>
  <div v-if="reqDetail !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl relative w-full">
      <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <div class="flex justify-center items-center">
        <h1 class="heading-1 text-center" v-if="reqDetail.taskTitle !== ''">{{reqDetail.taskTitle}}</h1>
        <h1 class="heading-1 text-center" v-else>Job #{{reqDetail.taskId}}</h1>
      </div>
    </div>
    <div>
      <div class="lg:grid grid-cols-3 gap-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div v-if="!reqDetail.isTaskCompleted" class="text-error heading-6 font-semibold">(Task is not complete)</div>
            <div v-if="reqDetail.isTaskCompleted" class="text-success heading-6 font-semibold">(Completed Task)</div>
            <div class="relative">
              <p>Task #{{reqDetail.taskId}}</p>
              <span class=" text-primary cursor-pointer" v-if="reqDetail.customerName !== ''" @click.stop="customerDetail">{{reqDetail.customerName}} ({{ reqDetail.isLead ? 'Lead' : 'Customer' }})</span>
              <p class="">Task Date: {{reqDetail.taskDate | dayDateType }}</p>
            </div>
          </div>
          <div v-if="reqDetail.latitude !== 0 && reqDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="flex justify-between items-center mb-2">
              <p class="font-bold">Address</p>
            </div>
            <div class="">
              <div class="mb-4 flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(reqDetail.latitude, reqDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">
                  <span v-if="reqDetail.fullAddress !== ''">{{reqDetail.fullAddress}}</span>
                  <span v-else>
                    <span v-if="reqDetail.addressLine1 !== ''">{{reqDetail.addressLine1}}, </span> 
                    <span v-if="reqDetail.addressLine2 !== ''">{{reqDetail.addressLine2}}, </span> 
                    <span v-if="reqDetail.city !== ''">{{reqDetail.city}}, </span> 
                    <span v-if="reqDetail.state !== ''">{{reqDetail.state}}, </span> 
                    <span v-if="reqDetail.country !== ''">{{reqDetail.country}}-</span> 
                    <span v-if="reqDetail.zip !== ''">{{reqDetail.zip}}</span>
                  </span>
                  </p>
                  <p  class="text-text1 heading-6">
                    <span v-if="reqDetail.houseNo !== ''">#: {{reqDetail.houseNo}}</span>
                    <span v-if="reqDetail.floorNumber !== ''"> (Floor: {{reqDetail.floorNumber}})</span>
                  </p>
                  <p v-if="reqDetail.buildingName !== ''" class="text-text1 whitespace-pre-wrap">Bldg: {{reqDetail.buildingName}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="reqDetail.latitude === 0 && reqDetail.longitude === 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="">
              <div class="flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="text-gray4 heading-5">At Office</p>
                </div>
              </div>
            </div>
          </div>
          <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p v-if="reqDetail.taskDescription !== ''" class="text-text1 font-bold mb-2 heading-4 ">Instructions</p>
              <p v-else class="text-text1 heading-4 ">No Instructions</p>
              <p class="whitespace-pre-line">{{reqDetail.taskDescription}}</p>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="font-bold">Schedule</p>
              <p v-if="!allDay" class="text-gray4">{{reqDetail.startTime | logTimeShow}} - {{reqDetail.endTime | leaveTimeFilterForActivity}}</p>
              <p v-else class="text-gray4">{{reqDetail.startTime | allDayTimeFill}}: Full-Day</p>
              <p class="text-success font-bold mt-2" v-if="reqDetail.isCompleted">Visit Completed</p>
            </div>
          </div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class=" relative">
              <p  class="font-bold mb-2">Assign member(s)</p>
              <div class="flex gap-2 flex-wrap">
                <div class="flex flex-wrap cursor-pointer" v-for="(user, index) in reqDetail.assignToList" :key="index">
                  <chip :chipText="`${user.firstName}  ${user.lastName}`"/>
                </div> 
              </div>
              <p class="pt-2"><i class="fa-solid fa-bell text-primary"></i> {{this.checkTime(reqDetail.reminderBeforeMinutes)}}</p>
            </div>
          </div>
          <!-- <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p class="text-text1 font-bold mb-2 heading-4 ">Repeats</p>
              <p v-if="displayText !== ''">{{displayText}}</p>
              <p v-if="displayText === ''">Do not Repeat</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {dateSuffixFilter} from '@/utils/dateEndVal.js'
var moment = require('moment')
import Chip from '@/View/components/chip.vue';
import ADMINAPI from '@/View/Admin/api/Admin.js'

export default {
  name: "lead",
  components: {
    Chip,
  },
  data() {
    return {
      weekDayList: [
        {
          displayVal: 'S',
          dayVal: 0,
          isSelected: false,
          fullName: 'Sun',
        },
        {
          displayVal: 'M',
          dayVal: 1,
          isSelected: false,
          fullName: 'Mon',
        },
        {
          displayVal: 'T',
          dayVal: 2,
          isSelected: false,
          fullName: 'Tue',
        },
        {
          displayVal: 'W',
          dayVal: 3,
          isSelected: false,
          fullName: 'Wed',
        },
        {
          displayVal: 'T',
          dayVal: 4,
          isSelected: false,
          fullName: 'Thu',
        },
        {
          displayVal: 'F',
          dayVal: 5,
          isSelected: false,
          fullName: 'Fri',
        },
        {
          displayVal: 'S',
          dayVal: 6,
          isSelected: false,
          fullName: 'Sat',
        },
      ],
      scheduleDate: '',
      showMenuList: false,
      overView: null,
      reqDetail: null,
      orgDetail: null,
      anyTime: false,
      addUpdateshedule: false,
      morning: false,
      afternoon: false,
      evening: false,
      deleteReqConfirm: false,
      deleteReqConfirmwithSel: false,
      allDay: false,
      displayText: '',
      reminderList: [
        {remId: 0, title: 'No reminder set', value: -1},
        {remId: 1, title: 'At start of task', value: 0},
        {remId: 2, title: '30 minutes before', value: 30},
        {remId: 3, title: '1 hour before', value: 60},
        {remId: 4, title: '2 hour before', value: 120},
        {remId: 5, title: '5 hour before', value: 300},
        {remId: 5, title: '24 hour before', value: 1440},
      ],
    };
  },
  created() {
  },
  mounted() {
    document.title = 'Task Detail'
    setTimeout(() => {
        this.scheduleDate = this.$route.query.date
        this.getDetail()
        this.$root.$on('confirmBoxHandler', (data) => {
          if (data && this.deleteReqConfirm) {
            this.deleteTaskFunc(false)
          }
          document.body.style = 'overflow: visible;'
          this.deleteReqConfirm = false
        })
    }, 200);
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
  },
  methods: {
    closeList () {
      this.showMenuList = false
    },
    customerDetail () {
      if (this.reqDetail.isLead) {
        this.$router.push({name: 'LeadsDetail', params: {customerId: this.reqDetail.customerId}})
      } else {
        this.$router.push({name: 'CustomersDetail', params: {customerId: this.reqDetail.customerId}})
      }
    },
    checkTime (data) {
      let dataA = ''
      this.reminderList.forEach(element => {
        if (element.value === parseInt(data)) {
          dataA = element.title
        }
      });
      return dataA
    },
    deleteFun () {
      if (!this.reqDetail.isRepeated) {
        this.deleteReqConfirm = true
      } else {
        this.deleteReqConfirmwithSel = true
      }
    },
    editRequest () {
      this.$router.push({name: 'UpdateNewTask', params: {taskId: parseInt(this.$route.params.taskId)}, query: {date: this.scheduleDate}})
    },
    getDetail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      ADMINAPI.GetTaskDetail(
        parseInt(this.$route.params.orgId),
        parseInt(this.$route.params.taskId),
        this.scheduleDate,
        (response) => {
          this.reqDetail = response.Data
          if (this.reqDetail.latitude === 0 && this.reqDetail.longitude === 0) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
            ADMINAPI.getOrgDetail(
              parseInt(this.$route.params.orgId),
              (response) => {
                this.orgDetail = response.Data
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              },
              (err) => {
                this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }
            );
          }
         if (response.Data.startTime !== "" && response.Data.endTime) {
          var startTime = moment(response.Data.startTime,)
          var endTime = moment(response.Data.endTime,)
          var hoursDiff = endTime.diff(startTime, 'hours')
          console.log("hoursDiff", hoursDiff);
          var minutesDiff = endTime.diff(startTime, 'minutes') % 60
          console.log("minutesDiff", minutesDiff);
          if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
            this.allDay = true
          } else {
            this.allDay = false
          }
         }
        this.displayText = '';
        const everyRepeatCount = this.reqDetail.repeatEvery;
        console.log('this.reqDetail.taskRepeatRule', this.reqDetail)
        const repeatType = this.reqDetail.taskRepeatRule.repeatType
        const repeatRule = this.reqDetail.taskRepeatRule.repeatRule
        const repeatArr = repeatRule ? JSON.parse(repeatRule) : null;
        console.log('repeatArr', repeatArr);
        this.$store.dispatch("SetLoader", { status: false, message: "" });
        if (parseInt(this.reqDetail.repeatEvery, 32) <= 0) {
          return false;
        }
        if (repeatType === 'd') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Daily'
              : `Every ${everyRepeatCount} days`;
        } else if (repeatType === 'w') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Weekly on '
              : `Every ${everyRepeatCount} weeks on `;
          if (repeatRule.length === 0) {
            return false;
          }
          repeatArr.map((x, index) => {
            // this.displayText += index === repeatArr.length - 1 ? 'and ' : '';
            this.displayText +=
              index === repeatArr.length - 1 && repeatArr.length > 1
                ? ' & '
                : index > 0
                ? ', '
                : '';
            this.displayText += `${this.weekDayList[x].fullName}`;
          });
        } else if (repeatType === 'm') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Monthly on '
              : `Every ${everyRepeatCount} month on `;
          if (repeatArr.length === 0) {
            return false;
          }
          repeatArr.map((x, index) => {
            this.displayText +=
              index === repeatArr.length - 1 && repeatArr.length > 1
                ? ' & '
                : index > 0
                ? ', '
                : '';
            this.displayText += `${x > -1 ? x : 'Last Day'}${
              x > -1 ? dateSuffixFilter(x) : ''
            }`;
          });
        } else if (repeatType === 'wd') {
          this.displayText =
            parseInt(everyRepeatCount, 32) === 1
              ? 'Monthly on'
              : `Every ${everyRepeatCount} month on`;
          const fstWeek = [];
          const sWeek = [];
          const tWeek = [];
          const fWeek = [];
          for (const day in repeatArr) {
            console.log('repeatArr day', repeatArr[day]);
            repeatArr[day].map(x => {
              console.log('Week numbers for ', day, x);
              switch (x) {
                case 1:
                  fstWeek.push(day);
                  break;
                case 2:
                  sWeek.push(day);
                  break;
                case 3:
                  tWeek.push(day);
                  break;
                case 4:
                  fWeek.push(day);
                  break;
                default:
                  break;
              }
            });
          }
          let isValid = false;
          if (fstWeek.length > 0) {
            console.log('fstWeek', fstWeek);
            isValid = true;
            this.displayText += ' 1st Week (';
            fstWeek.map((x, index) => {
              console.log('First week array ', x);
              this.displayText +=
                index === fstWeek.length - 1 && fstWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += '),';
          }
          if (sWeek.length > 0) {
            isValid = true;
            this.displayText += ' 2nd Week (';
            sWeek.map((x, index) => {
              console.log('Second week array ', x);
              this.displayText +=
                index === sWeek.length - 1 && sWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += '),';
          }
          if (tWeek.length > 0) {
            isValid = true;
            this.displayText += ' 3rd Week (';
            tWeek.map((x, index) => {
              console.log('Third week array ', x);
              this.displayText +=
                index === tWeek.length - 1 && tWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += '),';
          }
          if (fWeek.length > 0) {
            isValid = true;
            this.displayText += ' 4th Week (';
            fWeek.map((x, index) => {
              console.log('Fourth week array ', x);
              this.displayText +=
                index === fWeek.length - 1 && fWeek.length > 1
                  ? ' & '
                  : index > 0
                  ? ', '
                  : '';
              this.displayText += `${this.weekDayList[x].fullName}`;
            });
            this.displayText += ')';
          }
          console.log('Repeat this.displayText', isValid);
        }
        console.log('Repeat this.displayText', this.displayText);
        this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
  },
};
</script>
<style scoped>
</style>